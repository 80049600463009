.form-2 {
  background-color: $color_alabaster_approx;
  .section-title {
    margin-bottom: 0.5rem;
  }
  h2 {
    margin-bottom: 1.375rem;
  }
  h3 {
    margin-bottom: 1rem;
  }

  button[type="submit"][disabled] {
    background-color: #666;
  }

  .fas {
    color: #00a600;
  }
}

.custom-contactform__location {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

.icq,
.icq label,
.icq input {
  z-index: 999;
  position: absolute !important;
  height: 0;
  width: 0;
  border: none;
  background: none;
  margin: 0;
  top: 0;
  left: 0;
  clear: both;
  font-size: 0px;
  line-height: 0px;
  transform: scale(0.01);
}

@media (min-width: 768px) {
  .form-2 .list-unstyled {
    li {
      display: inline-block;
      margin-right: 1rem;
    }
    .address {
      display: block;
    }
  }
}

@media (min-width: 992px) {
  .form-2 {
    .text-container {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1200px) {
}

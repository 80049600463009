.custom-textmedia--img-full-text-bottom {
  [class*="col-"] {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  p {
    margin-bottom: 0;
    margin-top: 1rem;
  }
  .custom-textmedia__image {
    order: -1;
  }
}

.custom-textmedia--img-full-text-bottom,
.custom-textmedia--img-full-text-top {
  .text-container {
    margin-right: 0 !important;
  }
}
.custom-textmedia--img-full-text-bottom {
  .custom-textmedia__texts {
    margin-top: 40px;
  }
}
.custom-textmedia--img-full-text-top {
  .custom-textmedia__texts {
    margin-bottom: 40px;
  }
}

// .custom-textmedia--img-left .custom-textmedia__image {
//   order: -1;
// }
.custom-textmedia--img-full-text-top [class*="col-"] {
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

@media (min-width: 992px) {
  .custom-textmedia--img-left .custom-textmedia__image {
    order: -1;
  }
}

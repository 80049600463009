.form-control-submit-button {
  display: inline-block;
  width: 100%;
  height: 3.125rem;
  border: 0.125rem solid $btn-form-border-color;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: $btn-form-background-color;
  color: $btn-form-color;
  font: 700 0.75rem/1.75rem $font_0, $font_1;
  cursor: pointer;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  &:hover {
    background-color: $btn-form-background-color-hover;
    color: $btn-form-color-hover;
    border-color: $btn-form-border-color-hover;
  }
}

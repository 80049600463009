.custom-infobox {
  .section-title {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  h2 {
    text-align: center;
  }
  .card {
    border: none;
    background-color: transparent;
  }
  .card-title {
    margin-bottom: 20px;
    text-align: center;
  }
  .card-body {
    padding: 2.25rem 2rem 2.125rem 2rem;
    border: 1px solid $color_ebb_approx;
    //Instead of the line below you could use @include border-bottom-left-radius($radius)
    border-bottom-left-radius: 0.375rem;
    //Instead of the line below you could use @include border-bottom-right-radius($radius)
    border-bottom-right-radius: 0.375rem;
    background-color: $color_alabaster_approx;
    hyphens: auto;
  }

  .list-unstyled {
    margin-bottom: 1.5rem;
    .fas {
      color: $primary;
      font-size: 0.5rem;
      line-height: 1.375rem;
    }
    .media-body {
      margin-left: 0.625rem;
    }
  }
  .button-container {
    margin-top: -1.375rem;
    text-align: center;
  }
  .card-image img {
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 0.375rem;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 0.375rem;
  }
  .btn-solid-reg:hover {
    background-color: $white;
  }
}

@media (max-width: 992px) {
  .custom-infobox {
    .card:first-child {
      margin-top: 0 !important;
    }
    .card:last-child {
      margin-bottom: 0 !important;
    }
  }
}

@media (min-width: 992px) {
  .custom-infobox {
    .card {
      max-width: 18.25rem;
      min-width: 18.25rem;
      display: inline-block;
      vertical-align: top;
    }
    .col-lg-12 div.card:nth-child(3n + 2) {
      margin-right: 1.25rem;
      margin-left: 1.25rem;
    }
  }
}

@media (min-width: 1200px) {
  .custom-infobox {
    .card {
      max-width: 20.875rem;
      min-width: 20.875rem;
    }
    .card-body {
      padding: 2.25rem 2.5rem 2.125rem 2.5rem;
    }
    .col-lg-12 div.card:nth-child(3n + 2) {
      margin-right: 3rem;
      margin-left: 3rem;
    }
  }
}

body {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  color: $color_limed_ash_approx;
  font: 400 1rem/1.5625rem $font_2, $font_1;
  overflow-x: hidden;
}
html {
  width: 100%;
  height: 100%;
}
p {
  color: $body-font-color;
  margin-top: 0;
  font: 400 1rem/1.5625rem $font_2, $font_1;
}
.p-large {
  font: 400 1.125rem/1.625rem $font_2, $font_1;
}
.p-small {
  font: 400 0.875rem/1.375rem $font_2, $font_1;
}

.indent {
  padding-left: 1.25rem;
}
ul:not(.navbar-nav) {
  list-style: none;
  padding-left: 0;
  li:before {
    margin-right: 0.625rem;
    content: "\f0c8";
    font-family: "Font Awesome 5 Free";
    color: #14bf98;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-size: 0.5rem;
    font-weight: 900;
    line-height: 1.375rem;
    text-rendering: auto;
  }
}
h1 {
  color: $color_cabbage_pont_approx;
  font: 700 2.5rem/3rem $font_0, $font_1;
}
h2 {
  color: $color_cabbage_pont_approx;
  font: 700 1.75rem/2.125rem $font_0, $font_1;
}
h3 {
  color: $color_cabbage_pont_approx;
  font: 700 1.375rem/1.75rem $font_0, $font_1;
}
h4 {
  color: $color_cabbage_pont_approx;
  font: 700 1.25rem/1.625rem $font_0, $font_1;
}
h5 {
  color: $color_cabbage_pont_approx;
  font: 700 1.125rem/1.5rem $font_0, $font_1;
}
h6 {
  color: $color_cabbage_pont_approx;
  font: 700 1rem/1.375rem $font_0, $font_1;
}
a {
  color: $body-font-color;
  text-decoration: underline;
  &:hover {
    color: $body-font-color;
    text-decoration: underline;
  }
  &.green {
    color: $primary;
  }
  &.white {
    color: $color_athens_gray_approx;
  }
}
.white {
  color: $color_athens_gray_approx;
}
.testimonial-text {
  font-style: italic;
}
.testimonial-author {
  font: 700 1rem/1.375rem $font_0, $font_1;
}
strong {
  color: $color_cabbage_pont_approx;
}
.section-title {
  color: $section-color;
  font: 500 0.8125rem/1.125rem $font_0, $font_1;
}

.btn-solid-reg {
  display: inline-block;
  padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
  border: 0.125rem solid $btn-solid-reg-border-color;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: $btn-solid-reg-background-color;
  color: $btn-solid-reg-color;
  font: 700 0.75rem/0 $font_0, $font_1;
  text-decoration: none;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  &:hover {
    border-color: $btn-solid-reg-border-color-hover;
    background-color: $btn-solid-reg-background-color-hover;
    color: $btn-solid-reg-color-hover;
    text-decoration: none;
  }
}
.btn-solid-lg {
  display: inline-block;
  padding: 1.375rem 2.125rem 1.375rem 2.125rem;
  border: 0.125rem solid $primary;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: $primary;
  color: $white;
  font: 700 0.75rem/0 $font_0, $font_1;
  text-decoration: none;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  &:hover {
    background-color: transparent;
    color: $primary;
    text-decoration: none;
  }
}
.btn-outline-reg {
  display: inline-block;
  padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
  border: 0.125rem solid $color_limed_ash_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: transparent;
  color: $body-font-color;
  font: 700 0.75rem/0 $font_0, $font_1;
  text-decoration: none;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  &:hover {
    background-color: $color_limed_ash_approx;
    color: $white;
    text-decoration: none;
  }
}
.btn-outline-lg {
  display: inline-block;
  padding: 1.375rem 2.125rem 1.375rem 2.125rem;
  border: 0.125rem solid $color_limed_ash_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: transparent;
  color: $color_limed_ash_approx;
  font: 700 0.75rem/0 $font_0, $font_1;
  text-decoration: none;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  &:hover {
    background-color: $color_limed_ash_approx;
    color: $white;
    text-decoration: none;
  }
}
.btn-outline-sm {
  display: inline-block;
  padding: 1rem 1.625rem 0.9375rem 1.625rem;
  border: 0.125rem solid $color_limed_ash_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: transparent;
  color: $color_limed_ash_approx;
  font: 700 0.75rem/0 $font_0, $font_1;
  text-decoration: none;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  &:hover {
    background-color: $color_limed_ash_approx;
    color: $white;
    text-decoration: none;
  }
}
.form-group {
  position: relative;
  margin-bottom: 1.25rem;
  &.has-error.has-danger {
    margin-bottom: 0.625rem;
    .help-block.with-errors ul {
      margin-top: 0.375rem;
    }
  }
}
.label-control {
  position: absolute;
  top: 0.8125rem;
  left: 1.375rem;
  color: $body-font-color;
  opacity: 1;
  font: 400 0.875rem/1.375rem $font_2, $font_1;
  cursor: text;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s ease;
}
@media screen and(-ms-high-contrast: active),
  screen and(-ms-high-contrast: none) {
  .label-control {
    top: 0.9375rem;
  }
  .form-control-input {
    padding-top: 1.25rem;
    padding-bottom: 0.75rem;
    line-height: 1.75rem;
  }
  .form-control-select {
    padding-top: 0.875rem;
    padding-bottom: 0.75rem;
    height: 3.125rem;
    line-height: 2.125rem;
  }
  input[type="checkbox"] {
    vertical-align: -9%;
  }
}
.form-control-input {
  display: block;
  width: 100%;
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.3125rem;
  border: 1px solid $color_alto_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: $white;
  color: $body-font-color;
  font: 400 0.875rem/1.375rem $font_2, $font_1;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  &:focus {
    border: 1px solid $color_silver_chalice_approx;
    outline: none;
    + .label-control {
      top: 0.125rem;
      opacity: 1;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
  &:hover {
    border: 1px solid $color_silver_chalice_approx;
  }
  &.notEmpty + .label-control {
    top: 0.125rem;
    opacity: 1;
    font-size: 0.75rem;
    font-weight: 500;
  }
}
.form-control-textarea {
  display: block;
  width: 100%;
  height: 8rem;
  padding-top: 1.25rem;
  padding-left: 1.3125rem;
  border: 1px solid $color_alto_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: $white;
  color: $body-font-color;
  font: 400 1rem/1.5625rem $font_2, $font_1;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  &:focus {
    border: 1px solid $color_silver_chalice_approx;
    outline: none;
    + .label-control {
      top: 0.125rem;
      opacity: 1;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
  &:hover {
    border: 1px solid $color_silver_chalice_approx;
  }
  &.notEmpty + .label-control {
    top: 0.125rem;
    opacity: 1;
    font-size: 0.75rem;
    font-weight: 500;
  }
}
.form-control-select {
  display: block;
  width: 100%;
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.3125rem;
  border: 1px solid $color_alto_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background-color: $white;
  color: $body-font-color;
  font: 400 0.875rem/1.375rem $font_2, $font_1;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 3rem;
  &:focus {
    border: 1px solid $color_silver_chalice_approx;
    outline: none;
  }
  &:hover {
    border: 1px solid $color_silver_chalice_approx;
  }
}
select {
  appearance: none;
  background-image: $url_0;
  background-position: 96% 50%;
  background-repeat: no-repeat;
  outline: none;
}
select::-ms-expand {
  display: none;
}
.checkbox {
  font: 400 0.875rem/1.375rem $font_2, $font_1;
}
input[type="checkbox"] {
  vertical-align: -15%;
  margin-right: 0.375rem;
}

@-webkit-keyframes tada {
  from {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1, 1, 1);
  }
  10% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  20% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  50% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  70% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  90% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  80% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1, 1, 1);
  }
  10% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  20% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  50% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  70% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  90% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  80% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  animation-name: tada;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.my-mfp-slide-bottom {
  .zoom-anim-dialog {
    opacity: 0;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.2s ease-out;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  }
  &.mfp-ready {
    .zoom-anim-dialog {
      opacity: 1;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translateY(0) perspective(37.5rem) rotateX(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  &.mfp-removing {
    .zoom-anim-dialog {
      opacity: 0;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
  &.mfp-bg {
    opacity: 0;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: opacity 0.2s ease-out;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.6s;
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  animation: fadeOut 0.8s;
}
.spinner-wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $color_elephant_approx;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3.75rem;
  height: 1.25rem;
  margin: -0.625rem 0 0 -1.875rem;
  text-align: center;
  > div {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 100%;
    background-color: $white;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .bounce1 {
    animation-delay: -0.32s;
  }
  .bounce2 {
    animation-delay: -0.16s;
  }
}
@-webkit-keyframes sk-bouncedelay {
  0% {
  }
  80% {
  }
  100% {
  }
  40% {
  }
}
@keyframes sk-bouncedelay {
  0% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0);
  }
  80% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0);
  }
  100% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0);
  }
  40% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1);
  }
}

@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.basic-1 {
  .text-container {
    margin-bottom: 3rem;
  }
  .section-title {
    margin-bottom: 0.5rem;
  }
  .testimonial-author {
    color: $color_cabbage_pont_approx;
  }
  .image-container {
    overflow: hidden;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0.25rem;
    img {
      margin: 0;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0.25rem;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.3s;
    }
  }
}

.slider {
  padding-top: 6.375rem;
  padding-bottom: 6.5rem;
  background-color: $color_alabaster_approx;
  h2 {
    margin-bottom: 0.75rem;
    text-align: center;
  }
  .slider-container {
    position: relative;
  }
  .swiper-container {
    position: static;
    width: 90%;
    text-align: center;
  }
  .swiper-button-prev {
    left: -0.5rem;
    background-image: url($data_uri_0);
    background-size: 1.125rem 1.75rem;
    &:focus {
      outline: none;
    }
  }
  .swiper-button-next {
    right: -0.5rem;
    background-image: url($data_uri_1);
    background-size: 1.125rem 1.75rem;
    &:focus {
      outline: none;
    }
  }
  .card {
    position: relative;
    border: none;
    background-color: transparent;
    .card-body {
      padding-bottom: 0;
    }
  }
  .card-image {
    width: 6rem;
    height: 6rem;
    margin-right: auto;
    margin-bottom: 0.25rem;
    margin-left: auto;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50%;
  }
  .testimonial-text {
    margin-bottom: 0.625rem;
  }
  .testimonial-author {
    color: $color_cabbage_pont_approx;
  }
}

.lightbox-basic {
  position: relative;
  max-width: 62.5rem;
  margin: 2.5rem auto;
  padding: 1.5625rem;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.25rem;
  background: $white;
  text-align: left;
  img {
    margin-right: auto;
    margin-bottom: 2rem;
    margin-left: auto;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0.25rem;
  }
  .line-heading {
    width: 3rem;
    height: 1px;
    margin-top: 0;
    margin-bottom: 0.625rem;
    margin-left: 0;
    border: none;
    background-color: $primary;
  }
  h6 {
    margin-bottom: 2rem;
  }
  .testimonial-container {
    margin-top: 1.75rem;
    margin-bottom: 2.125rem;
    padding: 1.125rem 1.5rem 1.125rem 1.5rem;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0.25rem;
    background-color: $color_alabaster_approx;
  }
  .testimonial-text {
    margin-bottom: 0.5rem;
  }
  .testimonial-author {
    margin-bottom: 0;
  }
  .btn-solid-reg {
    margin-right: 0.375rem;
    margin-bottom: 1rem;
  }
  .btn-outline-reg {
    margin-right: 0.375rem;
    margin-bottom: 1rem;
  }
  a.mfp-close.as-button,
  button.mfp-close.as-button {
    position: relative;
    width: auto;
    height: auto;
    margin-right: 0.5rem;
    color: $color_cabbage_pont_approx;
    opacity: 1;
    &:hover {
      color: $concrete;
    }
  }
  button.mfp-close.x-button {
    position: absolute;
    top: -0.375rem;
    right: -0.375rem;
    width: 2.75rem;
    height: 2.75rem;
  }
}
.basic-2 {
  padding-top: 6.375rem;
  padding-bottom: 3.375rem;
  background-color: $color_alabaster_approx;
  text-align: center;
  h2 {
    margin-bottom: 0.75rem;
  }
  .team-member {
    display: inline-block;
    max-width: 13rem;
    margin-right: 1.5rem;
    margin-bottom: 3.5rem;
    margin-left: 1.5rem;
    .p-large {
      margin-bottom: 0.25rem;
    }
    .job-title {
      margin-bottom: 0.75rem;
      color: $color_cabbage_pont_approx;
      font: 700 1rem/1.375rem $font_0, $font_1;
    }
  }
  .image-wrapper {
    overflow: hidden;
    margin-bottom: 1.5rem;
    img {
      margin: 0;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.3s;
    }
    &:hover img {
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: scale(1.15);
    }
  }
  .fa-stack {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.125rem;
    margin-left: 0.125rem;
    .hexagon {
      position: absolute;
      left: 0;
      width: 1.75rem;
      height: 1.75rem;
      background: $url_2 center center no-repeat;
      background-size: 1.75rem 1.75rem;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.2s ease;
    }
    &:hover {
      .hexagon {
        background: $url_5 center center no-repeat;
        background-size: 1.75rem 1.75rem;
      }
      .fa-stack-1x {
        color: $primary;
      }
    }
  }
  .fa-stack-1x {
    font-size: 0.6875rem;
    line-height: 1.75rem;
    color: $white;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.2s ease;
  }
}
.counter {
  padding-top: 6.875rem;
  padding-bottom: 5.25rem;
  .image-container {
    margin-bottom: 3rem;
    img {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0.25rem;
    }
  }
  .section-title {
    margin-bottom: 0.5rem;
  }
  h2 {
    margin-bottom: 1.375rem;
  }
  .list-unstyled {
    margin-bottom: 1.5rem;
    .fas {
      color: $primary;
      font-size: 0.5rem;
      line-height: 1.375rem;
    }
    .media-body {
      margin-left: 0.625rem;
    }
  }
  #counter {
    text-align: center;
    .cell {
      display: inline-block;
      width: 9.5rem;
      margin-bottom: 0.75rem;
    }
    .counter-value {
      display: inline-block;
      margin-bottom: 0.125rem;
      color: $primary;
      font: 700 2.875rem/3.25rem $font_0, $font_1;
      vertical-align: middle;
    }
    .counter-info {
      display: inline-block;
      margin-left: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      vertical-align: middle;
    }
  }
}

.footer {
  padding-top: 5rem;
  background-color: $color_elephant_approx;
  .text-container {
    margin-bottom: 2.25rem;
  }
  h4 {
    margin-bottom: 0.75rem;
    color: $white;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: inherit;
  }
  ul {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: inherit;
  }
  a {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: inherit;
    &.footer__link {
      font-size: 13px;
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.copyright {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  background-color: $color_elephant_approx;
  text-align: center;
  p {
    color: $color_athens_gray_approx;
    text-decoration: none;
  }
  a {
    color: $color_athens_gray_approx;
    text-decoration: none;
  }
}
.ex-header {
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-color: $color_elephant_approx;
  text-align: center;
  h1 {
    color: $white;
  }
}
.ex-basic-1 {
  padding-top: 2rem;
  padding-bottom: 0.875rem;
  background-color: $color_alabaster_approx;
  .breadcrumbs {
    margin-bottom: 1rem;
    .fa {
      margin-right: 0.5rem;
      margin-left: 0.625rem;
    }
  }
}
.ex-basic-2 {
  padding-top: 4.75rem;
  padding-bottom: 4rem;
  h3 {
    margin-bottom: 1rem;
  }
  .text-container {
    margin-bottom: 3.625rem;
    &.last {
      margin-bottom: 0;
    }
  }
  .list-unstyled {
    .fas {
      color: $primary;
      font-size: 0.5rem;
      line-height: 1.375rem;
    }
    .media-body {
      margin-left: 0.625rem;
    }
  }
  .form-container {
    margin-top: 2.5rem;
  }
  .btn-outline-reg {
    margin-top: 1.75rem;
  }
}
@media (min-width: 768px) {
  .slider {
    .swiper-button-prev {
      left: 1rem;
      width: 1.375rem;
      background-size: 1.375rem 2.125rem;
    }
    .swiper-button-next {
      right: 1rem;
      width: 1.375rem;
      background-size: 1.375rem 2.125rem;
    }
  }
  .ex-header {
    padding-top: 11rem;
    padding-bottom: 9rem;
  }

  .counter #counter {
    text-align: left;
  }

  .ex-basic-2 .form-container {
    margin-top: 0.5rem;
  }
}
@media (min-width: 992px) {
  .basic-1 {
    .text-container {
      margin-bottom: 0;
    }
  }

  .lightbox-basic {
    img {
      margin-bottom: 0;
    }
    h3 {
      margin-top: 0.375rem;
    }
    .btn-solid-reg {
      margin-bottom: 0;
    }
    .btn-outline-reg {
      margin-bottom: 0;
    }
  }

  .ex-basic-2 {
    padding-bottom: 5rem;
  }
  .basic-2 .team-member {
    width: 12.75rem;
    max-width: 100%;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
  .counter .image-container {
    margin-bottom: 0;
  }
  .ex-header h1 {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .counter {
    padding-bottom: 6.125rem;
    .image-container {
      margin-right: 1.5rem;
    }
    .text-container {
      margin-top: 0.375rem;
      margin-right: 2.5rem;
      margin-left: 2rem;
    }
  }

  .basic-1 .text-container {
    margin-right: 3rem;
  }

  .basic-2 .team-member {
    width: 14.375rem;
    margin-right: 1.375rem;
    margin-left: 1.375rem;
  }
  .footer .text-container.about {
    margin-right: 5rem;
  }
  .ex-header h1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }
  .ex-basic-2 .form-container {
    margin-left: 1.75rem;
  }
}

.li-space-lg li {
  margin-bottom: 0.375rem;
}
#lmsgSubmit.h3.text-center {
  display: block;
  margin-bottom: 0;
  color: $color_well_read_approx;
  font: 400 1.125rem/1rem $font_2, $font_1;
  color: $white;
  &.tada.animated {
    display: block;
    margin-bottom: 0;
    color: $color_well_read_approx;
    font: 400 1.125rem/1rem $font_2, $font_1;
    color: $white;
  }
}

#pmsgSubmit.h3.text-center {
  display: block;
  margin-bottom: 0;
  color: $color_well_read_approx;
  font: 400 1.125rem/1rem $font_2, $font_1;
  &.tada.animated {
    display: block;
    margin-bottom: 0;
    color: $color_well_read_approx;
    font: 400 1.125rem/1rem $font_2, $font_1;
  }
}
.help-block.with-errors {
  .list-unstyled {
    color: $body-font-color;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: left;
  }
  ul {
    margin-bottom: 0;
  }
}

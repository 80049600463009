$main-component-padding-top: 100px;
$main-component-padding-bottom: 100px;

$main-component-padding-top-md-down: 50px;
$main-component-padding-bottom-md-down: 50px;

.main-component {
  padding-top: $main-component-padding-top;
  padding-bottom: $main-component-padding-bottom;
  position: relative;

  &__headline {
    margin-bottom: 22px;
  }
}

$svg-default-offset: 50%;
$svg-target-offset: 29%;
$svg-handshake-offset: 0%;
$svg-euro-offset: 0%;
.main-svg {
  $comp: &;
  position: absolute;
  &--left {
    left: 0;
    transform: translateX(#{$svg-default-offset * -1});
  }
  &--right {
    right: 0;
    transform: translateX(#{$svg-default-offset});
  }

  // svg target
  &--left#{$comp}--target {
    transform: translateX(#{$svg-target-offset * -1});
  }
  &--right#{$comp}--target {
    transform: translateX(#{$svg-target-offset}) scaleX(-1);
  }

  // svg handshake
  &--left#{$comp}--handshake,
  &--right#{$comp}--handshake {
    transform: translateX(#{$svg-handshake-offset});
  }
  // svg handshake
  &--left#{$comp}--euro,
  &--right#{$comp}--euro {
    transform: translateX(#{$svg-handshake-offset});
  }
  &--euro {
  }
}

@media (max-width: 992px) {
  .main-component {
    padding-top: $main-component-padding-top-md-down;
    padding-bottom: $main-component-padding-bottom-md-down;
  }
}

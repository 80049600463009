@keyframes bounce-4 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  5% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  15% {
    transform: scale(0.9, 1.1) translateY(-15px);
  }
  25% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

$font-path: "webfonts/Literata/static/";

// @import url("https://fonts.googleapis.com/css2?family=Literata:wght@400;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Karma:wght@400;600;700&display=swap");

// 400 regular
@font-face {
  font-family: Roboto;
  src: url("/webfonts/roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

// 500 medium
@font-face {
  font-family: Roboto;
  src: url("/webfonts/roboto/Roboto-Medium.ttf");
  font-weight: 500;
}

// 700 bold
@font-face {
  font-family: Roboto;
  src: url("/webfonts/roboto/Roboto-Medium.ttf");
  font-weight: 700;
}

//fonts
$font_0: Roboto;
// $font_0: Literata;
// $font_0: Karma;
$font_1: sans-serif;
$font_2: Roboto;
// $font_2: Literata;
// $font_2: Karma;

//colors
$white: #fff;
// $primary: #14bf98;
$primary-dark-40: #004d00;
$primary-dark-30: #007900;
$primary-dark-20: #00a600;
$primary-dark-10: #00d200;
$primary: #00ff00;
$primary-light-10: #2dff2d;
$primary-light-20: #59ff59;
$primary-light-30: #86ff86;
$primary-light-30: #b3ffb3;

$secondary: $primary-dark-20;

$body-font-color: #040404;

$black_10: rgba(0, 0, 0, 0.1);
$color_elephant_approx: #113448;
$black_3: rgba(0, 0, 0, 0.03);
$color_limed_ash_approx: #787976;
$color_cabbage_pont_approx: #484a46;
$color_athens_gray_approx: #dfe5ec;
$color_alto_approx: #dadada;
$color_silver_chalice_approx: #a1a1a1;
$color_well_read_approx: #b93636;
$color_heather_approx: #b5bcc4;
$color_alabaster_approx: #fbfbfb;
$color_ebb_approx: #ebe8e8;
$color_loblolly_approx: #c1cace;
$color_java_approx: #1bd1a7;
$color_calypso_approx: #39728f;
$color_casal_approx: #2a5d77;
$color_raven_approx: #7b7e85;
$black: #000;
$black_40: rgba(0, 0, 0, 0.4);
$concrete: #f2f2f2;

@mixin basic-transition() {
  transition: all 0.2s;
}

//data-uris
$data_uri_0: "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23787976'%2F%3E%3C%2Fsvg%3E";
$data_uri_1: "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23787976'%2F%3E%3C%2Fsvg%3E";

//urls
$url_0: url(../images/down-arrow.png);
$url_1: url(../images/header-background.jpg);
$url_2: url(../images/hexagon-green.svg);
$url_5: url(../images/hexagon-white.svg);
$scroll-to-top-image: url(../images/up-arrow.png);

// SECTION
$section-color: $secondary;

// BUTTON REG
$btn-solid-reg-border-color: $secondary;
$btn-solid-reg-border-color-hover: $secondary;
$btn-solid-reg-background-color: $secondary;
$btn-solid-reg-background-color-hover: transparent;
$btn-solid-reg-color: $white;
$btn-solid-reg-color-hover: $secondary;

$btn-form-color: $white;
$btn-form-color-hover: $white;
$btn-form-background-color: $secondary;
$btn-form-background-color-hover: $primary-dark-10;
$btn-form-border-color: $secondary;
$btn-form-border-color-hover: $primary-dark-10;

// SERVICES
$services-button-group-button-color: $color_raven_approx;
$services-button-group-button-color-hover: $white;
$services-button-group-button-color-active: $white;
$services-button-group-button-background-color: $color_athens_gray_approx;
$services-button-group-button-background-color-hover: $secondary;
$services-button-group-button-background-color-active: $secondary;

.filter {
  .section-title {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  h2 {
    text-align: center;
    margin-bottom: 3.25rem;
  }
  .button-group {
    text-align: center;
    a {
      display: inline-block;
      margin-right: 0.1875rem;
      margin-bottom: 0.5rem;
      margin-left: 0.1875rem;
      padding: 0.3125rem 1.375rem 0.25rem 1.375rem;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0.25rem;
      background-color: $services-button-group-button-background-color;
      color: $services-button-group-button-color;
      font: 700 1.25rem/1.5rem $font_0, $font_1;
      cursor: pointer;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.2s ease;
      &:hover {
        background-color: $services-button-group-button-background-color-hover;
        color: $services-button-group-button-color-hover;
      }
      &.button.is-checked {
        background-color: $services-button-group-button-background-color-active;
        color: $services-button-group-button-color-active;
      }
    }
  }
  .grid {
    margin-top: 1.25rem;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0.375rem;
  }
  .element-item {
    position: relative;
    float: left;
    overflow: hidden;
    width: 50%;
    height: 140px;
    background-color: $black;
    img {
      max-width: 100%;
      margin: 0;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.3s;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    .element-item-overlay {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black_40;
      opacity: 1;
      text-align: center;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.3s;
      span {
        position: absolute;
        z-index: 3;
        top: 42%;
        right: 0;
        left: 0;
        color: $white;
        font: 700 1.125rem/1.625rem $font_0, $font_1;
      }
    }
    &:hover img {
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: scale(1.15);
    }
  }
}

.services-contact-info {
  p {
    font-size: 0.9em;
    font-weight: bold;
    line-height: 1.5;
  }
}

.service-modal {
  max-height: 92vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (min-width: 992px) {
  .service-modal {
    max-height: none;
    overflow-y: visible;
    overflow-x: visible;
  }
}
@media (min-width: 992px) {
  .filter .element-item {
    width: calc(100% / 3);
    height: 170px;
    .element-item-overlay {
      opacity: 0;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: opacity 0.3s ease;
    }
    &:hover .element-item-overlay {
      opacity: 1;
    }
  }
}

@media (min-width: 1200px) {
  .filter .element-item {
    width: 25%;
    height: 200px;
    .element-item-overlay span {
      top: 45%;
    }
  }
}

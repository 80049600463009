.navbar-custom {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  max-width: 100vw;
  background-color: $color_elephant_approx;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0.0625rem 0.375rem 0 $black_10;
  font: 500 0.875rem/0.875rem $font_2, $font_1;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.2s;

  .navbar-brand {
    font-size: 0;
    &.logo-text {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      font: 700 2.25rem/1.5rem $font_0, $font_1;
      color: $white;
      text-decoration: none;
    }
    // &.logo-image img {
    //   width: 7.0625rem;
    //   height: 2rem;
    // }
    &.logo-image svg {
      width: auto;
      height: 30px;
    }
  }
  // .logo-image svg path.ziel {
  //   fill: $white;
  // }
  // &.top-nav-collapse .logo-image svg path.ziel {
  //   fill: $white;
  // }
  .navbar-nav {
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .dropdown-menu {
    margin-top: 0;
    border: none;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0.25rem;
    background-color: $color_elephant_approx;
  }
  .dropdown-item {
    color: $white;
    text-decoration: none;
    &:hover {
      background-color: $color_elephant_approx;
      .item-text {
        color: $primary;
      }
    }
    .item-text {
      font: 500 0.875rem/0.875rem $font_2, $font_1;
    }
  }
  .navbar-toggler {
    border: none;
    color: $white;
    font-size: 2rem;
  }
  .social-icons {
    display: flex;
    justify-content: center;
    &__icon {
      margin-left: 12px;
      margin-right: 12px;
      img {
        height: 24px;
        width: auto;
      }
    }
  }
  .nav-item {
    &:not(:last-child) {
      border-bottom: 1px solid $white;
    }
    padding: 12px 0px;
    .nav-link {
      padding: 0.625rem 0.75rem 0.625rem 0.75rem;
      color: $white;
      text-decoration: none;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.2s ease;
      &:hover {
        color: $primary;
      }
      &.active {
        color: $primary;
      }
    }
  }
  .dropdown:hover > .dropdown-menu {
    display: block;
    min-width: auto;
    animation: fadeDropdown 0.2s;
  }
  .dropdown-toggle:focus {
    outline: 0;
  }
  button[aria-expanded="false"] .navbar-toggler-awesome.fas {
    &.fa-times {
      display: none;
    }
    &.fa-bars {
      display: inline-block;
    }
  }
  button[aria-expanded="true"] .navbar-toggler-awesome.fas {
    &.fa-bars {
      display: none;
    }
    &.fa-times {
      display: inline-block;
      margin-right: 0.125rem;
    }
  }
}

// min width 1200
@media (min-width: 1200px) {
  .navbar-custom {
    padding: 2.125rem 1.5rem 2.125rem 2rem;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 11%,
      rgba(0, 0, 0, 0.2) 100%
    );
    .navbar-nav {
      margin-top: 0;
      margin-bottom: 0;
    }
    .social-icons {
      display: flex;
      margin-left: 0.5rem;
      justify-content: flex-start;
      &__icon {
        margin-left: 4px;
        margin-right: 4px;
        height: 100%;
        img {
          height: 16px;
        }
      }
    }
    &.top-nav-collapse {
      padding: 0.375rem 1.5rem 0.375rem 2rem;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 0.0625rem 0.375rem 0 $black_10;
      background-color: $color_elephant_approx;
      .dropdown-menu {
        border-top: 0.125rem solid rgba(0, 0, 0, 0);
      }
      .navbar-brand.logo-text {
        color: $white;
      }
    }
    .dropdown-menu {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 0.75rem solid rgba(0, 0, 0, 0);
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0.25rem;
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: 0 0.25rem 0.375rem 0 $black_3;
    }
    .dropdown-item {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
    .dropdown-items-divide-hr {
      width: 84%;
    }
    .navbar-brand.logo-text {
      color: $white;
    }

    // .logo-image svg path.ziel {
    //   fill: $white;
    // }

    .nav-item {
      border-bottom: medium none currentColor !important;
      .nav-link {
        padding: 0.25rem 0.75rem 0.25rem 0.75rem;
        font-size: 18px;
      }
    }
  }
}

// min width 1600
@media (min-width: 1600px) {
  .navbar-custom {
    padding-right: 12rem;
    padding-left: 12rem;
    &.top-nav-collapse {
      padding-right: 12rem;
      padding-left: 12rem;
    }
  }
}

$stage-scroller-icon-color: $white;
$stage-scroller-icon-color-hover: $primary;
// $stage-scroller-border-color: $white;
// $stage-scroller-border-color-hover: $primary;
$stage-text-shadow: 1px 1px 2px #000;

.header {
  background-size: cover;
  height: 100vh;
  position: relative;
  background-position: center;

  // .header-content {
  //   padding-top: 8rem;
  //   padding-bottom: 2.125rem;
  //   text-align: center;
  // }
  .carousel-control-next,
  .carousel-control-prev {
    text-decoration: none;
    opacity: 1;
    &:hover .fas:before {
      color: $primary;
    }
    .fas {
      background-image: none;
    }
  }
  .text-container {
    position: absolute;
    top: 70%;
    transform: translateY(-50%);
    padding: 0 60px;
  }
  &__headline {
    text-shadow: $stage-text-shadow;
    margin-bottom: 0.5rem;
    color: $white;
    padding: 0px 1rem;
    max-width: 100vw;
    hyphens: auto;
    word-break: break-word;
    // word-break: break-all;
    // overflow-x: hidden;
    // text-overflow: ellipsis;
  }
  &__subheadline {
    text-shadow: $stage-text-shadow;
    color: $white;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0px 1rem;
    max-width: 100vw;
    hyphens: auto;
    word-break: break-word;
    margin-top: 30px;
  }
  .p-large {
    margin-bottom: 2rem;
    color: $color_athens_gray_approx;
    text-shadow: $stage-text-shadow;
  }
}

.header-page-scroll {
  // border: solid 1px $stage-scroller-border-color;
  display: block;
  width: fit-content;
  padding: 4px 64px;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  @include basic-transition;
  .fas {
    transform: scaleX(1.4);
    color: $stage-scroller-icon-color;
    @include basic-transition;
    animation-name: bounce-4;
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  &:hover {
    // border: solid 1px $stage-scroller-border-color-hover;
    .fas {
      color: $stage-scroller-icon-color-hover;
    }
  }
}

@media (min-width: 768px) {
  .header {
    &__headline {
      font: 700 3.5rem/4rem $font_0, $font_1;
      letter-spacing: 3px;
    }
    &__subheadline {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.scroll-to-top {
  position: fixed;
  z-index: 999;
  right: 0.75rem;
  bottom: 0.75rem;
  display: none;
  width: 2.625rem;
  height: 2.625rem;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 1.875rem;
  background: $primary-dark-30 $scroll-to-top-image no-repeat center 47%;
  background-size: 1.125rem 1.125rem;
  text-indent: -9999px;
  @include basic-transition;

  &:hover {
    background-color: $primary-dark-20;
  }
}
